
















import { Component, Vue, Prop } from 'vue-property-decorator'
import isPast from 'date-fns/isPast'
import { timeDateJoin } from '@/lib/utils'

@Component
export default class TimeSlot extends Vue {
  @Prop() timeSlot
  @Prop() selectedSlots
  @Prop() isBooked
  @Prop() date
  @Prop() promotion

  get selected () {
    return this.selectedSlots.find(i => i.id === this.timeSlot.id)
  }

  get booked () {
    return this.expired || this.isBooked(this.timeSlot)
  }

  get expired () {
    const bookDate = timeDateJoin(this.timeSlot.startTime, this.date)
    return isPast(bookDate)
  }

  onClick () {
    if (this.booked) return

    this.$emit('add', this.timeSlot)
  }
}
