


















































import { Component, Vue } from 'vue-property-decorator'
import Cart from '@/components/Cart.vue'
import RoomSlots from '@/components/RoomSlots.vue'
import Client from '@/graphql/client'
import roomsGQL from '@/graphql/queries/rooms_list.graphql'
import ReservationsGQL from '@/graphql/queries/reservations_by_date.graphql'
import {
  RoomsListQuery,
  RoomsListQueryVariables,
  TimeSlot,
  ReservationsQuery,
  ReservationsByDateQuery,
  ReservationsByDateQueryVariables,
  ReservationStatus,
  RoomStatus
} from '@/generated/graphql'
import { Bookings } from '@/lib/booking_types'
import { toGQLDate, conflict } from '@/lib/utils'
import CartStore from '@/store/modules/cart'
import swal from 'sweetalert2'
import { format, parseISO, add, sub, isToday } from 'date-fns'

@Component({ components: { Cart, RoomSlots } })
export default class Home extends Vue {
  footerHeight = 65
  cta: string | undefined
  rooms: RoomsListQuery['rooms'] = []
  extras: RoomsListQuery['extras'] = []
  promotions: RoomsListQuery['promotions'] = []
  bookings: Bookings = []
  showDetails = false
  theme = {
    container: {
      dark: 'calendar'
    }
  }

  bookingLoaded = true
  loaded = false

  async mounted () {
    if ('success' in this.$route.query) {
      swal.fire('Order Completed!', 'We have sent you an email with your booking details. We look forward to seeing you!', 'success')
      CartStore.clear()
    }
    if ('failed' in this.$route.query) {
      swal.fire({
        title: 'Error!',
        text: (this.$route.query.message as string).split('_').join(' '),
        icon: 'error'
      })
    }

    const resp = await Client.query<RoomsListQuery, RoomsListQueryVariables>(roomsGQL, {
      date: toGQLDate(this.date),
      status: RoomStatus.Published
    })
    this.cta = resp.setting?.value
    this.rooms = resp.rooms
    this.extras = resp.extras
    this.bookings = resp.reservations
    this.promotions = resp.promotions

    this.loaded = true
  }

  get promotion () : number | undefined {
    return this.promotions.find(promotion => promotion.dayOfWeek === this.date.getDay())?.reduction
  }

  prevDay () {
    if (isToday(this.date)) return
    this.date = sub(this.date, { days: 1 })
    this.fetchBookings()
  }

  nextDay () {
    this.date = add(this.date, { days: 1 })
    this.fetchBookings()
  }

  get date () {
    return CartStore.date
  }

  set date (val) {
    CartStore.setDate(val)
  }

  get displayDate (): string {
    return format(this.date, 'EEEE, MMMM do yyyy')
  }

  get selection (): Record<string, TimeSlot[]> {
    return CartStore.selectedTimeSlots
  }

  set selection (val) {
    CartStore.setSelectedTimeSlots(val)
  }

  get offsetStyle () {
    return `margin-bottom: ${this.footerHeight + 20}px;`
  }

  async fetchBookings () {
    this.bookingLoaded = false
    const response = await Client.query<ReservationsByDateQuery, ReservationsByDateQueryVariables>(ReservationsGQL, {
      date: toGQLDate(this.date), statuses: [ReservationStatus.Paid, ReservationStatus.Manual]
    })
    this.bookings = response.reservations
    this.resetCartSelection()
    this.bookingLoaded = true
  }

  resetCartSelection () {
    this.selection = {}
    CartStore.setSelectedExtras({})
  }

  isBooked ({ room, startTime, endTime }: TimeSlot) {
    return this.bookedTimesFor(room).find(slot => conflict({
      startA: startTime,
      endA: endTime,
      startB: slot.startTime,
      endB: slot.endTime
    }))
  }

  slotTimeToDate (time: string) {
    let date = format(this.date, 'yyyy-MM-dd')
    date += `T${time}Z`
    return parseISO(date)
  }

  bookedTimesFor (room: ReservationsQuery['rooms'][0]) {
    return this.bookings.flatMap((booking) => booking!.reservationTimeSlots.filter((bookedSlot) => bookedSlot!.room.id === room!.id))
  }

  roomSelections (roomId) {
    return this.selection[roomId] || []
  }

  addSlot (payload: { room: {id: string}, slot: TimeSlot }) {
    if (this.isBooked(payload.slot)) return

    if (this.roomSelections(payload.room.id).find(i => i.id === payload.slot.id)) {
      return CartStore.removeSlot({ room: payload.room.id, slot: payload.slot })
    }
    const selected = this.roomSelections(payload.room.id).concat([payload.slot])
    this.selection = { ...this.selection, ...{ [payload.room.id]: selected } }
  }
}
