
































import { GiftcardCheckoutMutation, GiftcardCheckoutMutationVariables } from '@/generated/graphql'
import client from '@/graphql/client'
import { Component, Vue } from 'vue-property-decorator'
import { required, email, maxLength, minLength } from 'vuelidate/lib/validators'
import checkoutGQL from '../graphql/mutations/giftcard_checkout.graphql'
import { loadStripe } from '@stripe/stripe-js'

@Component({ components: {} })
export default class GiftCard extends Vue {
  amounts = [25, 50, 100, 150, 250, 500]
  processing = false
  stripe

  form = {
    recipient: '',
    message: '',
    amount: 100,
    senderName: ''
  }

  async mounted () {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE || 'noKey')
  }

  validations () {
    return {
      form: {
        senderName: { required, minLength: minLength(1), maxLength: maxLength(50) },
        recipient: { required, email },
        message: {
          maxLength: maxLength(200)
        }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  selectAmount (amount) {
    this.form.amount = amount
  }

  variant (amount) {
    if (this.form.amount === amount) {
      return 'dark'
    } else {
      return 'secondary'
    }
  }

  async submit () {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return
    this.processing = true
    const response = await client.query<GiftcardCheckoutMutation, GiftcardCheckoutMutationVariables>(checkoutGQL, {
      input: this.form
    })
    this.stripe.redirectToCheckout({ sessionId: response.giftcardCheckout })
  }
}
