





































































import { Component, Vue } from 'vue-property-decorator'
import CartStore from '@/store/modules/cart'
import { toGQLDate } from '@/lib/utils'
import { CheckoutQuery, CheckoutQueryVariables, TimeSlot, CartCheckoutMutation, CartCheckoutMutationVariables, CartInput, Maybe, PromotionsQuery } from '@/generated/graphql'
import Extra from '@/components/Extra.vue'
import Client, { sdk } from '@/graphql/client'
import gql from '@/graphql/queries/checkout.graphql'
import checkoutGQL from '@/graphql/mutations/cart_checkout.graphql'
import { loadStripe } from '@stripe/stripe-js'
import BookingDetails from '@/components/BookingDetails.vue'
import AddExtraModal from '@/components/AddExtra.vue'
import ApplyGiftcard from '@/components/ApplyGiftcard.vue'
import { format } from 'date-fns'

type Extras = CheckoutQuery['extras']
type Rooms = CheckoutQuery['rooms']

@Component({ components: { Extra, AddExtraModal, BookingDetails, ApplyGiftcard } })
export default class Checkout extends Vue {
  notes = ''
  rooms: Rooms = []
  extras: Extras = []
  checkingOut = false
  showExtras = false
  stripe
  loaded = false
  showExtraModal = false
  showApplyGiftcard = false
  activeExtra: Extras[number] | null = null
  showNote = false

  promotions: Maybe<PromotionsQuery['promotions']> = []

  get promotion () : number {
    if (!this.promotions) return 0
    return this.promotions.find((promotion) => {
      return promotion.dayOfWeek === CartStore.date.getDay()
    })?.reduction || 0
  }

  async mounted () {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE || 'noKey')
    const { extras, rooms } = await Client.query<CheckoutQuery, CheckoutQueryVariables>(gql)
    this.promotions = (await sdk.Promotions()).promotions
    this.extras = extras
    this.rooms = rooms
    this.loaded = true
  }

  get selectedExtras () {
    return CartStore.selectedExtras
  }

  get selection (): Record<string, TimeSlot[]> {
    return CartStore.selectedTimeSlots
  }

  get totalRooms () {
    return Object.values(this.selection)
      .flat()
      .reduce((memo, room) => {
        return memo + room.price * (1 - this.promotion / 100)
      }, 0)
  }

  get totalExtras () {
    let total = 0
    if (!this.extras.length) return 0
    Object.keys(this.selectedExtras).forEach((extraId) => {
      const extra = this.extras.find((extra) => extra.id === parseInt(extraId))!
      total += extra.price * this.selectedExtras[extraId]
    })
    return total
  }

  get discount () {
    return CartStore.discount
  }

  get total () {
    return this.totalRooms + this.totalExtras - this.discount
  }

  get date () {
    return format(CartStore.date, 'MMMM do yyyy')
  }

  room (id) {
    return this.rooms.find(r => r?.id === id)
  }

  onShowExtra (extra) {
    this.activeExtra = extra
    this.showExtraModal = true
  }

  async onCheckout () {
    this.checkingOut = true
    const input = {
      notes: this.notes,
      date: toGQLDate(CartStore.date),
      timeSlots: Object.values(this.selection).flat().map((slot) => slot.id),
      extras: Object.keys(this.selectedExtras).map((extraId) => { return { extra: parseInt(extraId), quantity: this.selectedExtras[extraId] } })
    } as CartInput
    if (this.discount) input.redeemCode = CartStore.giftCard
    const { cartCheckout } = await Client.query<CartCheckoutMutation, CartCheckoutMutationVariables>(checkoutGQL, { input })

    if (cartCheckout && cartCheckout.includes('redeemed')) {
      window.location = cartCheckout as unknown as Location
    } else {
      this.stripe.redirectToCheckout({ sessionId: cartCheckout })
    }
  }
}
