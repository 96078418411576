























import { Component, Vue, Prop } from 'vue-property-decorator'
import gql from '@/graphql/queries/giftcard.graphql'
import Client from '@/graphql/client'
import { GiftcardBalance } from '@/generated/graphql'
import CartStore from '@/store/modules/cart'

@Component
export default class AddExtra extends Vue {
  @Prop() show

  code = ''
  data: GiftcardBalance | null = null
  loading = false

  get visible () {
    return this.show
  }

  set visible (val) {
    this.$emit('toggle', val)
  }

  onFindGiftcard () {
    this.loading = true
    this.$nextTick(async () => {
      // wait for v-model to update after onpaste event
      const { giftcardBalance } = await Client.query(gql, { code: this.code })
      this.data = giftcardBalance
      this.loading = false
    })
  }

  onApply () {
    CartStore.setGiftcard(this.code)
    this.visible = false
  }
}
