























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import BookingDetails from '@/components/BookingDetails.vue'
import CartStore from '@/store/modules/cart'
import { Maybe, PromotionsQuery, RoomsListQuery } from '@/generated/graphql'
import { sdk } from '@/graphql/client'

@Component({ components: { BookingDetails } })
export default class Cart extends Vue {
  @Prop() rooms!: RoomsListQuery['rooms']
  @Prop() extras!: RoomsListQuery['extras']
  @Prop() date

  showDetails = false
  promotions: Maybe<PromotionsQuery['promotions']> = []

  async mounted () {
    this.promotions = (await sdk.Promotions()).promotions
  }

  get promotion () : number {
    if (!this.promotions) return 0
    return this.promotions.find((promotion) => {
      return promotion.dayOfWeek === this.date.getDay()
    })?.reduction || 0
  }

  get totalCost () {
    return this.slotCost + this.extraCost
  }

  get slotCost () {
    return Object.values(CartStore.selectedTimeSlots)
      .flat()
      .reduce((memo, room) => {
        return memo + (room.price * (1 - (this.promotion / 100)))
      }, 0)
  }

  get extraCost () {
    let total = 0
    if (!this.extras.length) return 0
    Object.keys(CartStore.selectedExtras).forEach((extraId) => {
      const extra = this.extras.find((extra) => extra.id === parseInt(extraId))!
      total += extra.price * CartStore.selectedExtras[extraId]
    })
    return total
  }

  get roomsWithSelections () {
    return Object.keys(CartStore.selectedTimeSlots)
      .filter(roomId => CartStore.selectedTimeSlots[roomId].length > 0)
      .map(roomId => this.roomById(roomId))
  }

  roomById (id: string) {
    return this.rooms.find(room => room?.id === parseInt(id))
  }

  @Watch('showDetails')
  onShowDetailsChange (val) {
    if (val) {
      // need to account for animation before getting height
      setTimeout(() => {
        const height = (this.$refs.cart as HTMLElement)?.offsetHeight
        this.$emit('height:update', height)
      }, 350)
    } else {
      this.$emit('height:update', 65)
    }
  }
}
