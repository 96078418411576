














import { Component, Vue, Prop } from 'vue-property-decorator'
import { ExtrasQuery } from '@/generated/graphql'
import CartStore from '@/store/modules/cart'

type ExtraItem = ExtrasQuery['extras'][number]

@Component
export default class AddExtra extends Vue {
  @Prop() extra!: ExtraItem
  @Prop() show

  quantity = 1

  mounted () {
    this.setQuantity()
  }

  updated () {
    this.setQuantity()
  }

  get visible () {
    return this.show
  }

  set visible (val) {
    this.$emit('toggle', val)
  }

  get image () {
    return this.extra.image?.variants?.large || this.extra.image?.path
  }

  get inCart () {
    return !!CartStore.selectedExtras[this.extra.id]
  }

  setQuantity () {
    this.quantity = this.inCart
      ? CartStore.selectedExtras[this.extra.id]
      : 1
  }

  onAdd () {
    CartStore.setExtraQuantity({ id: this.extra.id, quantity: this.quantity })
    this.visible = false
  }

  onRemove () {
    CartStore.removeExtra(this.extra.id)
    this.quantity = 0
    this.visible = false
  }
}
