










































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Room, TimeSlot as TimeSlotType } from '@/generated/graphql'
import TimeSlot from './TimeSlot.vue'

@Component({ components: { TimeSlot } })
export default class RoomSlots extends Vue {
  @Prop() room!: Room;
  @Prop() loaded!: boolean
  @Prop() promotion: number | undefined
  @Prop({
    default: () => {
      return []
    }
  })
  selectedSlots!: TimeSlotType[];

  @Prop() date!: Date
  @Prop() isBooked;

  get firstImage () {
    const image = this.room.images[0]
    return image.variants?.small || image.path
  }

  get images () {
    return this.room.images.map(i => i.variants?.large || i.path)
  }

  get timeSlots () {
    return this.room.timeSlots.filter(timeslot => timeslot!.dayOfWeek === this.date.getDay())
      .sort((a, b) => {
        return parseInt(a!.startTime.split(':')[0]) > parseInt(b!.startTime.split(':')[0]) ? 1 : -1
      })
  }

  get modalId () {
    return `room-${this.room.id}`
  }

  addSlot (slot) {
    this.$emit('slotSelected', { room: this.room, slot })
  }
}
