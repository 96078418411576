


























import { ExtrasQuery } from '@/generated/graphql'
import { Component, Vue, Prop } from 'vue-property-decorator'
import CartStore from '@/store/modules/cart'

type Extras = ExtrasQuery['extras']
type ExtraItem = Extras[number]

@Component
export default class Extra extends Vue {
  @Prop() extra!: ExtraItem;

  get thumbnail () {
    return this.extra.image?.variants?.thumb || this.extra.image?.path
  }

  get inCart () {
    return !!CartStore.selectedExtras[this.extra.id]
  }

  get quantity () {
    return CartStore.selectedExtras[this.extra.id]
  }

  onRemove () {
    CartStore.removeExtra(this.extra.id)
  }

  onSelect () {
    this.$emit('show:modal', this.extra)
  }
}
