
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import CartStore from '@/store/modules/cart'
import { Extra, Room } from '@/generated/graphql'

@Component({ components: { } })
export default class BookingDetails extends Vue {
  @Prop() rooms!: Room[]
  @Prop() extras!: Extra[]
  @Prop() promotion!: number

  room (id) {
    return this.rooms.find(r => r?.id === +id)
  }

  extra (id) {
    return this.extras.find(r => r?.id === +id)
  }

  extraPrice (extra) {
    return extra.quantity > 1 ? `${extra.price} x ${extra.quantity}` : extra.price
  }

  get selectedTimeslots () {
    return CartStore.selectedTimeSlots
  }

  get selectedExtras () {
    return Object.keys(CartStore.selectedExtras).map((extraId) => {
      const extra = this.extra(extraId)
      return {
        id: extraId,
        title: extra?.title,
        description: extra?.description,
        price: extra?.price,
        quantity: CartStore.selectedExtras[extraId]
      }
    })
  }

  remove (room: string, slot) {
    CartStore.removeSlot({ room, slot })
  }

  removeExtra (extraId) {
    CartStore.removeExtra(extraId)
  }

  get discount () {
    return CartStore.discount
  }

  get giftCard () {
    return CartStore.giftCard
  }

  removeGiftcard () {
    CartStore.setGiftcard(null)
  }
}
